import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Image from "components/UI/image";
import StaticPageMeta from "components/UI/staticPageMeta";

import { useWindow } from "context/windowContext";

import ArrowIcon from "images/svg/sliderArrow.svg";

import "styles/questionnaire-page.scss";

export const questionnaireStartPage = graphql`
  query QuestionnaireStartPage {
   allContentfulContentfulPage (filter: {name: {eq: "Questionnaire start page"}}) {
     nodes {
      name
      bannerItems {
        image {
          title
          file {
            url
            contentType
          }
          gatsbyImageData(
            placeholder: NONE,
            formats: [AUTO, WEBP, AVIF],
          )
        }
        link {
          href
        }
        title
      }
      richText {
        raw
      }
    }
    }
  }
`;


const QuestionnaireStartPageContent = (props) => {
    const { allContentfulContentfulPage } = useStaticQuery(questionnaireStartPage);
    const { bannerItems, richText } = allContentfulContentfulPage.nodes[0];
    const { location } = useWindow();

    const hash = location?.hash;

    return (
        <>
            <StaticPageMeta pageName={`Questionnaire Welcome`}/>
            <div className="questionnaire-start-page__content">
                {renderRichText(richText)}
                <div className="questionnaire-start-page__content-banners">
                    {
                        bannerItems.map((item, index) => (
                            !(hash === "#sexual-health" && index === bannerItems.length - 1) && ( // skip last item (hair loss) if it's not #sexual-health
                                <Link className="questionnaire-start-page__content-banner" to={item?.link?.href}
                                      key={index}>
                                    <div className="questionnaire-start-page__content-title-container">
                                        <p className="questionnaire-start-page__content-title">{item.title}</p>
                                        <div>
                                            <Image src={ArrowIcon}/>
                                        </div>
                                    </div>
                                </Link>
                            )
                        ))
                    }
                </div>
            </div>
        </>
    );
};

export default QuestionnaireStartPageContent;
