import React from "react";

import Main from "components/Layout/main";
import "styles/questionnaire-page.scss";
import QuestionnaireStartPageContent from "../components/UI/Questionnaire/questionnaireStartPageContent";
import QuestionnaireHeader from "components/UI/Questionnaire/questionnaireHeader";


const QuestionnaireStartPage = () => {

    return (
        <>
            <QuestionnaireHeader/>
            <Main isFooter={false} isHeader={false} className="questionnaire">
                <QuestionnaireStartPageContent />
            </Main>
        </>
    );
};

export default QuestionnaireStartPage;
