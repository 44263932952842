import React from 'react';
import { useStaticQuery, graphql } from "gatsby";

import Image from "components/UI/image";

export const questionnaireHeaderQuery = graphql`
  query questionnaireHeaderQuery {
    contentfulHeaderUpdated {
      logo {
        title
        file {
          url
          contentType
        }
        gatsbyImageData(
          placeholder: NONE,
          formats: [AUTO, WEBP, AVIF],
        )
      }
    }
  }
`;


const QuestionnaireHeader = () => {
    const { logo } = useStaticQuery(questionnaireHeaderQuery).contentfulHeaderUpdated;

    return (
        <div className="questionnaire__header">
            <Image image={logo}/>
        </div>
    );
};

export default QuestionnaireHeader;
